
import { computed, defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {useStore} from "vuex";

export default defineComponent({
  name: "Others",

  setup() {
    const u = localStorage.getItem("user") as any;
    const user = JSON.parse(u);
    const store = useStore();
    const dataList = [
      {
        name: "maritime_services",
        route: "/maritime_services",
        icon: "bi bi-bookmarks",
        count: "",
        permission: "maritime services: index",
      },
      {
        name: "ports",
        route: "/ports",
        icon: "bi bi-flag",
        count: "",
        permission: "ports: index",
      },
      {
        name: "goods_type",
        route: "/goods_type",
        icon: "bi bi-boxes",
        count: "",
        permission: "goods type: index",
      },
      {
        name: "docks",
        route: "/docks",
        icon: "bi bi-map",
        count: "",
        permission: "docks: index",
      },
      {
        name: "vessels",
        route: "/vessels",
        icon: "las la-ship",
        count: "",
        permission: "vessel: index",
      },
      {
        name: "itemplates",
        route: "/email-templates",
        icon: "las la-envelope-square",
        count: "",
        permission: "maritime services: index",
      },
      {
        name: "icarbrands",
        route: "/car_brands",
        icon: "las la-car",
        count: "",
        permission: "car brands: index",
      },
    ];
    const dataObject = ref<[] | any>([]);
    const search = ref("");
    const permissions = ref<[] | any>([]);

    const onSearch = () => {
      dataObject.value = dataList.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    const canViewCatalog = (item) => {
      if (JSON.stringify(user) !== "{}") {
        return permissions.value.filter((x) => x.name === item.permission).length > 0;
      }
      return false;
    };

    onMounted(() => {
      store.commit("setLoadingStatus", true);
      ApiService.get(`/api/permissions/me`)
          .then(({ data }) => {
            permissions.value = data;
          })
          .catch((error) => {
            store.commit("setLoadingStatus", false);
            console.log(error);
          });
      setCurrentPageBreadcrumbs("others", []);
      dataObject.value = dataList;
      ApiService.get("/api/catalogs/others").then(({ data }) => {
        dataObject.value.forEach((item, index) => {
          item.count = data[index];
          store.commit("setLoadingStatus", false);
        });
      });
    });

    return {
      dataObject,
      dataList,
      search,
      canViewCatalog,
      onSearch,
      permissions,
    };
  },
});
